import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { SimpleModal } from 'components/common/Modal/SimpleModal';
import {
  GridColDef,
  GridColumnVisibilityModel,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { LoadingButton } from '@mui/lab';
import { arraysEqual, pluralize } from 'utils/util';
import { MuiIconManifest } from 'utils/iconManifest';

interface ColumnsVisibilityPanelModalButtonProps {
  columnVisibilityModel: GridColumnVisibilityModel;
  loading: boolean;
  slotProps?: {
    button?: React.ComponentProps<typeof LoadingButton>;
  };
}

// #region Styles

const MenuItemStyle = (theme: Theme, last: boolean) => css`
  padding: 0;
  padding-left: ${theme.spacing(2)}px;
  border-bottom: ${last ? 'none' : `1px solid ${theme.palette.divider}`};
`;

const FormControlLabelStyle = css`
  margin: 0;
`;

const MaxHeightContentStyle = css`
  height: 300px; // for when searching and there's no results so it doesn't look bad
  overflow: auto;
`;

// #endregion

export function ColumnsVisibilityPanelModalButton({
  columnVisibilityModel,
  loading,
  slotProps,
}: ColumnsVisibilityPanelModalButtonProps) {
  const theme = useTheme();

  // use the grid api to get the columns
  const { current } = useGridApiContext();
  const columns = current.getAllColumns();
  const validColumns = columns.filter(
    (column) =>
      column.field !== 'actions' &&
      column.type !== 'checkboxSelection' &&
      column.hideable !== false &&
      typeof column.hideable !== 'undefined'
  );

  // state vars for the modal
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const visibleColumns = validColumns
    .filter((column) => columnVisibilityModel[column.field] === true)
    .map((column) => column.field);
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(visibleColumns);

  useEffect(() => {
    setSelectedColumns(
      columns
        .filter(
          (column) =>
            columnVisibilityModel[column.field] === true &&
            column.field !== 'actions' &&
            column.type !== 'checkboxSelection' &&
            column.hideable !== false
        )
        .map((column) => column.field)
    );
  }, [columns, columnVisibilityModel]);

  // filter by searchText and whether or not it exists in the columnVisibilityModel (i.e. delete column cannt be hid)
  const filteredColumns = validColumns.filter(
    (column) =>
      column.headerName?.toLowerCase().startsWith(searchText.toLowerCase()) &&
      columnVisibilityModel[column.field] !== undefined
  );

  const noFilteredColumns =
    filteredColumns.length === 0 && searchText.length > 0;

  // check if the selected columns are the same as the visible columns (to disable the confirm button if they are the same)
  const selectedColumnsAreSameAsVisible = arraysEqual(
    [...visibleColumns].sort(),
    [...selectedColumns].sort()
  );

  // #region handlers

  // function to reset the selected columns to the current columns
  const resetColumnsToCurrent = useCallback(() => {
    setSelectedColumns(
      validColumns
        .filter((column) => columnVisibilityModel[column.field] === true)
        .map((column) => column.field)
    );
  }, [validColumns, columnVisibilityModel]);

  // function to run when a column is selected
  const handleSelectionChange = useCallback(
    (column: GridColDef) => {
      if (selectedColumns.includes(column.field)) {
        setSelectedColumns((prev) =>
          prev.filter((field) => field !== column.field)
        );
      } else {
        setSelectedColumns((prev) => [...prev, column.field]);
      }
    },
    [selectedColumns]
  );

  // to prevent event bubbling from triggering this action when the Checkbox is clicked.
  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement>, column: GridColDef) => {
      const target = event.target as HTMLElement;
      if (target.tagName === 'INPUT') return;
      handleSelectionChange(column);
    },
    [handleSelectionChange]
  );

  // set the column visibility model to the selected columns
  const handleConfirm = useCallback(() => {
    current.setColumnVisibilityModel({
      ...validColumns.reduce(
        (acc, column) => {
          acc[column.field] = selectedColumns.includes(column.field);
          return acc;
        },
        { ...columnVisibilityModel }
      ),
      actions: true, // actions column cannot be hidden
    });
  }, [current, validColumns, selectedColumns, columnVisibilityModel]);

  // #endregion

  if (!current || !columnVisibilityModel || loading) return null;

  return (
    <>
      <LoadingButton
        color="grey"
        variant="roundedOutlined"
        startIcon={<MuiIconManifest.ViewColumnIcon />}
        onClick={() => {
          setOpen(true);
        }}
        loading={!current}
        disabled={!current}
        {...slotProps?.button}
      >
        {current ? 'Select columns' : 'Loading'}
      </LoadingButton>
      <SimpleModal
        open={open}
        title="Select columns"
        setOpen={setOpen}
        onCancel={resetColumnsToCurrent}
        confirmText="Apply"
        disableConfirm={selectedColumnsAreSameAsVisible}
        onConfirm={handleConfirm}
        maxWidth="sm"
        slotProps={{
          dialogContentProps: {
            sx: {
              padding: 0,
              margin: 0,
            },
          },
          dialogTitleProps: {
            style: {
              margin: 0,
            },
          },
        }}
      >
        <Stack>
          {/* search bar */}
          <TextField
            placeholder="Search"
            fullWidth
            margin="normal"
            variant="standard"
            size="small"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              startAdornment: (
                <MuiIconManifest.SearchIcon
                  fontSize="small"
                  sx={{ marginRight: 2 }}
                />
              ),
              endAdornment: searchText ? (
                <IconButton size="small" onClick={() => setSearchText('')}>
                  <MuiIconManifest.CloseIcon fontSize="small" />
                </IconButton>
              ) : null,
              disableUnderline: true,
            }}
            inputProps={{
              sx: {
                pt: 1,
                pb: 1,
              },
            }}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              margin: 0,
              p: 2,
              pl: 4,
            }}
          />

          <Grid
            container
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              p: 0,
            }}
          >
            <Grid
              xs={6}
              container
              item
              flexDirection="row"
              alignItems="center"
              sx={{
                paddingLeft: 2,
                borderRight: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Checkbox
                checked={selectedColumns.length === validColumns.length}
                indeterminate={
                  selectedColumns.length > 0 &&
                  selectedColumns.length < validColumns.length
                }
                disabled={noFilteredColumns}
                onChange={() => {
                  if (
                    (selectedColumns.length > 0 &&
                      selectedColumns.length < validColumns.length) ||
                    selectedColumns.length === validColumns.length
                  ) {
                    setSelectedColumns([]);
                  } else {
                    setSelectedColumns(
                      filteredColumns.map((column) => column.field)
                    );
                  }
                }}
              />
              <Typography variant="body1" fontWeight={500}>
                {filteredColumns.length} total{' '}
                {pluralize('column', filteredColumns.length, '', 's')} available
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                padding: 1,
                pl: 4,
                pr: 2,
              }}
            >
              <Grid item>
                <Typography variant="body1">
                  {selectedColumns.length
                    ? `
                ${selectedColumns.length} selected`
                    : 'No columns selected'}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  disabled={selectedColumns.length === 0}
                  onClick={() => {
                    setSelectedColumns([]);
                  }}
                >
                  Clear all
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            flexDirection="row"
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              mb: 2,
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                borderRight: `1px solid ${theme.palette.divider}`,
              }}
            >
              <MenuList className={MaxHeightContentStyle} disablePadding>
                {filteredColumns.map((column, index) => (
                  <MenuItem
                    key={`${column.field}-${index}`}
                    className={MenuItemStyle(
                      theme,
                      index === filteredColumns.length - 1
                    )}
                    onClick={(event) => {
                      handleMenuItemClick(event, column);
                    }}
                  >
                    <FormControlLabel
                      className={FormControlLabelStyle}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          checked={selectedColumns.includes(column.field)}
                          onChange={() => {
                            handleSelectionChange(column);
                          }}
                        />
                      }
                      label={column.headerName}
                    />
                  </MenuItem>
                ))}

                {noFilteredColumns && (
                  <Typography p={4}>
                    No columns found with that name. Please try a different
                    search term.
                  </Typography>
                )}
              </MenuList>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={MaxHeightContentStyle}
                sx={{
                  p: 2,
                }}
              >
                <Grid container spacing={2}>
                  {selectedColumns.map((column) => (
                    <Grid key={`${column}-grid`} item xs={12}>
                      <Chip
                        color="primary"
                        label={
                          validColumns.find((col) => col.field === column)
                            ?.headerName
                        }
                        onDelete={() => {
                          setSelectedColumns((prev) =>
                            prev.filter((field) => field !== column)
                          );
                        }}
                        deleteIcon={
                          <MuiIconManifest.CloseIcon
                            sx={{
                              pr: 1,
                            }}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </SimpleModal>
    </>
  );
}
