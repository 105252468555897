import {
  AnyWizardQuestion,
  ListWizardWizardParams,
  WizardConfig,
  WizardListParams,
  WizardListValueQuestion,
  WizardMultipleChoiceQuestion,
} from '../../../../components/Wizard/WizardConfig';

/**
 * Specific list config type, derived from the base WizardConfig type.
 * The generic type for TQuestionType lets us enforce field names to directly map to the WizardListParams,
 *  to add some guardrails to our API call.
 */
export type ListWizardQuestion = AnyWizardQuestion &
  (
    | WizardMultipleChoiceQuestion
    | WizardListValueQuestion
    | {
        name: keyof ListWizardWizardParams | keyof WizardListParams;
      }
  );
export type ListWizardConfig = WizardConfig<ListWizardQuestion> & {
  getCreateBasePath: () => string;
  getEditBasePath: (uuid: string) => string;
};

export function specificOrganizationsFlow({
  id,
  destinationQuestionId,
}: {
  id: ListWizardQuestion['id'];
  destinationQuestionId: ListWizardQuestion['id'];
}): ListWizardQuestion[] {
  return [
    {
      id,
      label: 'Upload or input the organizations for your list below.',
      type: 'input-upload',
      name: 'included_organizations',
      subType: 'organizations',
      nextQuestionId: destinationQuestionId,
    },
  ];
}

export const listWizardConfig: ListWizardConfig = {
  id: 'list-wizard',
  version: 1.6,
  title: 'List builder',
  basePath: '/', // ignored
  getCreateBasePath: () => '/app/myprospects/lists/new',
  getEditBasePath: (uuid) => `/app/myprospects/lists/${uuid}/edit`,
  description:
    'Create a list of your prospects, active pipeline, customers, or competitors to gain insights into the events they are attending.',
  questions: [
    {
      id: 'list-type',
      label: 'What type of list would you like to build?',
      type: 'multiple-choice',
      name: 'list_type',
      answers: [
        {
          label: 'Prospects',
          value: 'Prospects',
          icon: 'LeaderboardOutlinedIcon',
          nextQuestionId: 'list-output-type',
        },
        {
          label: 'Active Pipelines',
          value: 'Active pipeline',
          icon: 'FilterAltOutlinedIcon',
          nextQuestionId: 'list-output-type',
        },
        {
          icon: 'CardMembershipOutlinedIcon',
          label: 'Customers',
          value: 'Customers',
          nextQuestionId: 'list-output-type',
        },
        {
          label: 'Competitors',
          value: 'Competitors',
          icon: 'CompareArrowsIcon',
          nextQuestionId: 'competitor-organizations-input-upload',
        },
      ],
    },

    {
      id: `list-output-type`,
      label: 'Who is on your list?',
      name: 'list_source',
      type: 'multiple-choice',
      answers: [
        {
          label: 'People',
          description:
            'I am targeting specific individuals or personas (e/g job titles)',
          value: 'People',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'people-input-type',
        },
        {
          label: 'Organizations',
          description: 'I am targeting specific companies',
          value: 'Organizations',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'organization-input-type',
        },
      ],
    },

    // Company input question - select specific vs persona flow.
    {
      id: 'organization-input-type',
      label: 'How would you like to select companies for this list?',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Upload or input organizations.',
          value: 'upload',
          icon: 'CorporateFareOutlinedIcon',
          nextQuestionId: 'organizations-input-upload',
        },
        {
          label: 'Describe attributes of the organizations you want to target.',
          value: 'describe',
          icon: 'EditAttributesOutlinedIcon',
          nextQuestionId: 'organization-persona-flow',
        },
      ],
    },

    // People input question - select specific vs persona flow

    {
      id: 'people-input-type',
      label: 'How would you like to select people for this list?',
      type: 'multiple-choice',
      name: 'people_input_type',
      answers: [
        {
          label: 'Upload or input people.',
          value: 'upload',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'people-input-upload',
        },
        {
          label: 'Describe attributes of the people you want to target.',
          value: 'describe',
          icon: 'EditAttributesOutlinedIcon',
          nextQuestionId: 'people-job-titles',
        },
      ],
    },

    // #region Specific People flow.
    {
      id: 'people-input-upload',
      label: 'Upload or input the people you want to include in your list.',
      description: 'Upload field requirements: Full Name, Email.',
      type: 'input-upload',
      subType: 'people',
      name: 'included_people',
      nextQuestionId: 'value',
    },
    // #endregion

    // #region People Persona flow.
    {
      id: 'people-job-titles',
      label: 'Input or upload the job titles you would like to target.',
      type: 'input-upload',
      subType: 'job-titles-keywords',
      name: 'included_job_titles',
      nextQuestionId: 'people-job-titles-include-or-exclude',
    },

    {
      id: 'people-job-titles-include-or-exclude',
      label:
        'Are there any job titles or keywords you want to exclude from your list?',
      description: 'Include job titles/keywords',
      type: 'multiple-choice',
      answers: [
        {
          label: 'Skip',
          value: 'Skip',
          icon: 'SkipNextIcon',
          nextQuestionId: 'organization-input-type',
        },
        {
          label: 'Exclude job titles',
          value: 'Exclude job titles',
          icon: 'PeopleOutlineIcon',
          nextQuestionId: 'people-job-titles-exclude',
        },
      ],
    },

    {
      id: 'people-job-titles-exclude',
      label: 'Input or upload the job titles you would like to exclude.',
      type: 'input-upload',
      subType: 'job-titles-keywords',
      name: 'excluded_job_titles',
      nextQuestionId: 'organization-input-type',
    },

    // #endregion

    // #region Specific Organizations flow.
    ...specificOrganizationsFlow({
      id: 'organizations-input-upload',
      destinationQuestionId: 'value',
    }),

    ...specificOrganizationsFlow({
      id: 'competitor-organizations-input-upload',
      destinationQuestionId: 'list-name',
    }),

    // #endregion

    // #region Organization Persona flow.

    {
      id: 'organization-persona-flow',
      label:
        "How would you like to describe the organizations you're targeting?",
      type: 'multiple-choice',
      answers: [
        {
          label: 'Select a list of industries and company sizes.',
          value: 'attributes',
          nextQuestionId: 'organization-attributes-industry',
        },
        {
          label: 'Filter by an existing list.',
          value: 'existing-list',
          nextQuestionId: 'organization-attributes-filter-by-list',
        },
      ],
    },

    {
      id: 'organization-attributes-filter-by-list',
      label: 'Select from a pre-existing list you’ve already created.',
      type: 'input-upload',
      subType: 'prospect-lists',
      name: 'included_prospect_lists',
      allowUpload: false,
      nextQuestionId: 'value',
    },

    {
      id: 'organization-attributes-industry',
      label: 'What industries are you targeting?',
      type: 'tree-select-autocomplete',
      name: 'included_industries',
      subType: 'sectors',
      nextQuestionId: 'organization-attributes-size',
    },

    {
      id: 'organization-attributes-size',
      label: 'What size organizations are you targeting?',
      name: 'included_employee_ranges',
      type: 'input-upload',
      subType: 'organization-size',
      nextQuestionId: 'value',
    },
    // #endregion

    //  NOTE: these are related to CRM functionality.
    // {
    //   id: 'pipeline-1a',
    //   label:
    //     'What is the deal stage that represents the active pipeline you want to track? ',
    //   type: 'multiple-choice',
    //   name: 'include_deal_stage_options',
    //   answers: [
    //     {
    //       label: 'Deal stage',
    //       nextQuestionId: 'pipeline-1b',
    //     },
    //   ],
    // },
    // {
    //   id: 'pipeline-1b',
    //   label: 'Do you want to narrow down your pipeline by Deal owner?',
    //   type: 'multiple-choice',
    //   name: 'include_deal_owner_options',
    //   answers: [
    //     {
    //       label: 'Include deal owner',
    //       nextQuestionId: 'pipeline-1c',
    //     },
    //   ],
    // },
    // {
    //   id: 'pipeline-1c',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'exclude_deal_owner_options',
    //   answers: [
    //     {
    //       label: 'Exclude deal owner',
    //       nextQuestionId: 'pipeline-2',
    //     },
    //   ],
    // },

    // {
    //   id: 'pipeline-2a-people-CRM',
    //   label:
    //     'Who are the contacts, specific people you want to include in your active pipeline list?',
    //   type: 'multiple-choice',
    //   name: 'contact_owner_options',
    //   answers: [
    //     {
    //       label: 'Include contact owner',
    //       nextQuestionId: 'pipeline-2a-people-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'pipeline-2a-people-CRM-include',
    //   label:
    //     'Who are the contacts, specific people you want to exclude in your active pipeline list?',
    //   type: 'multiple-choice',
    //   // name: 'contact_owner_options', we currently don't have a way to exclude contact owner
    //   answers: [
    //     {
    //       label: 'Exclude contact owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },

    // {
    //   id: 'customers-1a',
    //   label: 'What is the deal stage that represents your current customer? ',
    //   type: 'multiple-choice',
    //   name: 'include_deal_stage_options',
    //   answers: [
    //     {
    //       label: 'Include deal stage',
    //       nextQuestionId: 'customers-2',
    //     },
    //   ],
    // },

    // {
    //   id: 'customers-2a-people-CRM',
    //   label:
    //     'Who are the contacts, specific people you want to include in your customers list ?',
    //   type: 'multiple-choice',
    //   name: 'contact_owner_options',
    //   answers: [
    //     {
    //       label: 'Include contact owner',
    //       nextQuestionId: 'customers-2a-people-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'customers-2a-people-CRM-include',
    //   label:
    //     'Who are the contacts, specific people you want to exclude in your customers list?',
    //   type: 'multiple-choice',
    //   // name: 'contact_owner_options', exclude contact owner is currently not part of the icp_params
    //   answers: [
    //     {
    //       label: 'Exclude contact owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },

    // {
    //   id: 'leads-people-CRM',
    //   label: 'Who do you want to include from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'crm_included_people',
    //   answers: [
    //     {
    //       label: 'Include contact owner',
    //       nextQuestionId: 'leads-people-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'leads-people-CRM-include',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   // name:'crm_included_people' we currently don't have a way to exclude contact owner
    //   answers: [
    //     {
    //       label: 'Exclude contact owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },

    // {
    //   id: 'leads-organizations-CRM',
    //   label: 'Who do you want to include from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'crm_company_options',
    //   answers: [
    //     {
    //       label: 'Include company owner',
    //       nextQuestionId: 'leads-organizations-CRM-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'leads-organizations-CRM-include',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   // name: 'crm_company_options', we currently don't have a way to exclude company owner
    //   answers: [
    //     {
    //       label: 'Exclude company owner',
    //       nextQuestionId: 'leads-organizations-personas',
    //     },
    //   ],
    // },

    // {
    //   id: 'leads-personas-targetpersonas-organizations-crm',
    //   label: 'Who do you want to include from your CRM import?',
    //   type: 'multiple-choice',
    //   name: 'company_owner_options',
    //   answers: [
    //     {
    //       label: 'Include company owner',
    //       nextQuestionId: 'leads-personas-targetpersonas-organizations-crm-include',
    //     },
    //   ],
    // },
    // {
    //   id: 'leads-personas-targetpersonas-organizations-crm-include',
    //   label: 'Who do you want to exclude from your CRM import?',
    //   type: 'multiple-choice',
    //   // name: 'company_owner_options', we currently don't have a way to exclude company owner
    //   answers: [
    //     {
    //       label: 'Exclude company owner',
    //       nextQuestionId: 'value',
    //     },
    //   ],
    // },
    {
      id: 'value',
      type: 'list-value',
      name: 'average_deal_size',
      label: 'Average value of prospect',
      description:
        'By assigning an average value to the prospects on your list, you can gain greater insights into the potential ROI from the events you sponsor and attend.',
      nextQuestionId: 'list-name',
    },
    {
      id: 'list-name',
      name: 'list_name',
      label: 'What name would you like to give your list?',
      placeholder: 'Enter list name',
      type: 'text-input',
      isFinal: true,
    },
  ],
};
