export const endpoints = {
  auth: {
    post: {
      login: '/api/v2/auth/login/',
      logout: '/api/v2/auth/logout/',
      resend_verification_email: '/api/v2/auth/resend_verification_email/',
    },
  },
  events: {
    get: {
      search: (term) => `/autocompleteevent?term=${encodeURIComponent(term)}`,
      summary: (eventId) => `/api/v1/event/${eventId}/event_summary/`,
      prospects: (eventId) => `/api/v2/event/${eventId}/prospects/`,
      attendeeSummaries: (eventId) =>
        `/api/v1/event/${eventId}/event_attendee_summaries/`,
      recommendedEvents: (eventId) =>
        `/api/v1/event/${eventId}/event_recommended_events/`,
      attendees: (eventId) => `/api/v1/event/${eventId}/attendees/`,
      sponsors: (eventId) => `/api/v1/event/${eventId}/sponsors/`,
      activateFusion: (eventId) => `/api/v1/event/${eventId}/activate_fusion/`,
      changeAttendance: (eventId) =>
        `/api/v1/event/${eventId}/change_attendance/`,
      sponsorshipAlert: (eventId) =>
        `/api/v1/event/${eventId}/sponsorship_alert/`,
      latestEventAlert: (eventId) => `/api/v1/event/${eventId}/latest_alert/`,
    },
    post: {
      logCSVDownload: (eventId, urlParams) =>
        `/api/v2/event/${eventId}/csv_download_detail_log/${
          urlParams ? `?${urlParams}` : ''
        }`,
      enrichAttendees: (eventId, urlParams) =>
        `/api/v1/event/${eventId}/enrich_event/${
          urlParams ? `?${urlParams}` : ''
        }`,
      evaluateEvent: (eventId) => `/api/v1/event/${eventId}/evaluate_event/`,
      uploadLeads: '/api/v1/event/upload_leads/',
      request_event: '/api/v1/event/request',
      custom_event_report: (event) =>
        `api/v1/prospects/${event}/export?type=events`,
      data_fix_request: ({ eventId }) =>
        `api/v1/event/${eventId}/data_fix_request/`,
    },
  },
  prospects: {
    get: {
      team_lists: '/api/v1/upl/lists/',
      myprospects: '/api/v1/prospects/my_lists/',
      myprospectsShort: (indirect) =>
        `/api/v1/prospects/my_lists_short/?indirect=${indirect}`,
      autocomplete_prospect_name: '/autocomplete_prospect_name/',
      view_list_organizations: (uuid) =>
        `/api/v1/prospects/${uuid}/view/organizations/`,
      view_list_profiles: (uuid) => `/api/v1/prospects/${uuid}/view/profiles/`,
      autocomplete_org: (query) =>
        `/api/v1/upl/autocomplete_org/?query=${encodeURIComponent(query)}`,
      v2_autocomplete_organizations: (query) =>
        `/api/v2/autocomplete/organization?term=${encodeURIComponent(query)}`,
      v2_autocomplete_profiles: (query) =>
        `/api/v2/autocomplete/profile?term=${encodeURIComponent(query)}`,
      v2_get_list: (uuid) => `/api/v2/prospects/my_lists/${uuid}/`,
    },
    delete: {
      delete_list: (uuid) => `/api/v1/prospects/${uuid}/delete_list/`,
      remove_from_list: (uuid) => `/api/v1/prospects/${uuid}/remove_prospect/`,
    },
    put: {
      clone_list: (uuid) => `/api/v1/prospects/${uuid}/clone/`,
      add_to_list: (uuid) => `/api/v1/prospects/${uuid}/add/`,
      create_list: '/api/v1/prospects/create/',
    },
    patch: {
      change_roles: (uuid) => `/api/v1/prospects/${uuid}/change_roles/`,
      transfer_ownership: (uuid) => `/api/v1/prospects/${uuid}/transfer/`,
      follow_org_members: (uuid) =>
        `api/v1/prospects/${uuid}/follow_org_members/`,
      rename_list: (uuid) => `api/v1/prospects/${uuid}/rename/`,
      update_icp_params: (uuid) =>
        `/api/v1/prospects/${uuid}/update_icp_params/`,
      update_wizard_params: (uuid) =>
        `/api/v2/prospects/${uuid}/update_wizard_params/`,
      toggle_crm_list_active: (uuid) =>
        `/api/v1/prospects/${uuid}/update_crm_list/`,
      deal_values: (uuid) => `/api/v2/prospects/${uuid}/deal_values/`,
    },
    post: {
      export: (prospectListUuid, type) =>
        `api/v1/prospects/${prospectListUuid}/export?type=${type}`,
      enrich: (prospectListUuid) =>
        `api/v1/prospects/${prospectListUuid}/enrich`,
      prospect_profiles: '/api/v1/upl/profiles/',
      prospect_orgs: '/api/v1/upl/orgs/',
    },
  },
  profile: {
    get: {
      profile_summary: '/ajax/profile_summary/{id}/',
      profile_data: '/ajax/profile/{id}/',
      profile_team: '/ajax/profile_team/{id}/',
      profile_engagement: '/ajax/profile_engagement/{id}/',
      profile_top_topics: '/ajax/profile_top_topics/{id}/',
    },
  },
  feed: {
    get: {
      summary: '/ajax/feed_summary/',
      notifications: '/ajax/feed_notifications/',
      attending: '/ajax/feed_attending/',
      unlocked: '/ajax/feed_unlocked/',
    },
  },
  user: {
    get: {
      state: '/api/v1/user_state/',
      capabilities: 'api/v2/user/default/capabilities/',
    },
    delete: {
      impersonating: 'api/v2/user/default/impersonating/',
    },
  },
  action_alerts: {
    get: {
      stripe_checkout_failed: '/ajax/stripe_checkout_failed/{sessionId}/',
      contact_sales: '/ajax/contact_sales/',
    },
  },
  industries: '/ajax/industries/', // returns list of industries
  autocomplete: {
    get: {
      companies: (term) =>
        `/autocompletecompany/?term=${encodeURIComponent(term)}`,
      topics: (term) => `/autocompletetopic/?term=${encodeURIComponent(term)}`,
      prospects: (term) =>
        `/autocomplete_prospect_name/?term=${encodeURIComponent(term)}`,
      orgs: (query) =>
        `/api/v1/upl/autocomplete_org/?query=${encodeURIComponent(query)}`,
      people: (query) =>
        `/api/v1/upl/autocomplete_profile/?query=${encodeURIComponent(query)}`,
    },
  },
  setup: '/ajax/completesetup/',
  teamEvents: {
    index: ({ ownerTeam = 'default' }) =>
      `/api/v1.1/team/${ownerTeam}/team_event/`,
    detail: ({ ownerTeam = 'default', id }) =>
      `/api/v1.1/team/${ownerTeam}/team_event/${id}`,
  },
  team: {
    get: {
      members: `/api/v1/team/members/`,
      directory: (teamUuid) =>
        `/api/v1/team/directory/${teamUuid ? `${teamUuid}/` : ''}`,
    },
    update: {
      team_data: `/api/v2/team/update_values/`,
    },
  },
  org: {
    get: {
      historical_events: ({ uuid, offset }) =>
        `/ajax/org/${uuid}/past/${offset}/`,
    },
  },
  leads: {
    post: {
      upload: `/ajax/leads_upload/`,
      matchFields: `/ajax/leads_match_fields/`,
    },
  },
  planner: {
    get: {
      events: (teamUuid) =>
        `/api/v1/planner/events/${teamUuid ? `${teamUuid}/` : ''}`,
      views: '/api/v2/planner/saved_views/',
      columnValue: ({ eventId, columnUuid }) =>
        `api/v2/planner/event/${eventId}/column/${columnUuid}/value/`,
    },
    post: {
      uploadCSV: '/api/v1/planner/upload_csv/',
      views: '/api/v2/planner/saved_views/',
      columns: (viewId) =>
        `/api/v2/planner/saved_views/${viewId}/saved_columns/`,
    },
    put: {
      columnValue: ({ eventId, columnUuid }) =>
        `api/v2/planner/event/${eventId}/column/${columnUuid}/value/`,
    },
    patch: {
      event: '/api/v1/planner/update_event/',
      view: (viewId) => `/api/v2/planner/saved_views/${viewId}/`,
      column: ({ viewId, columnUuid }) =>
        `/api/v2/planner/saved_views/${viewId}/saved_columns/${columnUuid}/`,
    },
    delete: {
      event: '/api/v1/planner/delete_event/',
      view: (viewId) => `/api/v2/planner/saved_views/${viewId}/`,
      column: ({ viewId, columnUuid }) =>
        `/api/v2/planner/saved_views/${viewId}/saved_columns/${columnUuid}/`,
      columnValue: ({ eventId, columnUuid }) =>
        `api/v2/planner/event/${eventId}/column/${columnUuid}/value/`,
    },
  },

  settings: {
    invitations: {
      list: (teamUuid) => `api/v1/team/${teamUuid}/invitation/`,
      single: ({ teamUuid: team_uuid, uuid }) =>
        `api/v1/team/${team_uuid}/invitation/${uuid}/`,
      resend: ({ teamUuid, uuid }) =>
        `api/v2/team/${teamUuid}/invitation/${uuid}/resend/`,
      accept: ({ uuid, team_uuid }) =>
        `api/v1/team/${team_uuid}/invitation/${uuid}/accept/`,
    },
    preferences: `api/v2/team/preferences/`, // GET, PATCH
    put: {
      userRole: (teamUuid, profileUuid) =>
        `api/v2/team/${teamUuid}/members/${profileUuid}/role/`,
    },
    patch: {
      patchUserPermission: (teamUuid) =>
        `api/v1/team/${teamUuid}/members/change_permission/`,
    },
    delete: {
      deleteUser: (profileUuid) => `api/v1/settings/${profileUuid}/delete/`,
    },
  },
  crm: {
    get: {
      crmFilters: (teamUuid) => `api/v2/crm/${teamUuid}/filters/`,
    },
  },

  reports: {
    list: `api/v2/event_reports/`,
    single: (uuid) => `api/v2/event_reports/${uuid}/`,
    eventList: (uuid) => `api/v2/event_reports/${uuid}/events/`,
  },

  industry: {
    get: {
      subindustries: 'api/v2/industry/taxonomy/subindustries/',
      industries: 'api/v2/industry/taxonomy/industries/',
      industryGroups: 'api/v2/industry/taxonomy/industry_groups/',
      sectors: 'api/v2/industry/taxonomy/sectors/',
    },
  },
};
